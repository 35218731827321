import React, { useState } from 'react';
import './App.css';
import logo from '../src/Logo.png';

const App = () => {
  const [revenue, setRevenue] = useState('');
  const [marginPercentage, setMarginPercentage] = useState('');
  const [commissionPercentage, setCommissionPercentage] = useState('');
  const [minCrossoverAmount, setMinCrossoverAmount] = useState('');
  const [salary, setSalary] = useState('');
  const [result, setResult] = useState(null);

  const calculateSalary = () => {
    const revenueNum = parseFloat(revenue);
    const marginNum = parseFloat(marginPercentage) / 100;
    const commissionNum = parseFloat(commissionPercentage) / 100;
    const minCrossoverNum = parseFloat(minCrossoverAmount);
    const salaryNum = parseFloat(salary);

    if (isNaN(revenueNum) || isNaN(marginNum) || isNaN(commissionNum) || isNaN(minCrossoverNum) || isNaN(salaryNum)) {
      alert("Please enter valid numbers.");
      return;
    }

    const profit = revenueNum * marginNum; 
    let remainingAmount = profit - minCrossoverNum; 

    remainingAmount = Math.max(0, remainingAmount);

    let commission = remainingAmount * commissionNum; 
    commission = Math.max(0, commission);
    
    const totalSalary = salaryNum + commission; 
    const remainingProfit = profit - totalSalary;

    const decreaseInProfitPercentage = ((totalSalary) / profit) * (marginNum * 100);
    const newProfitPercentage = ((remainingProfit) / profit) * (marginNum * 100); 

    setResult({
      profit: profit.toFixed(2),
      remainingAmount: remainingAmount.toFixed(2),
      commission: commission.toFixed(2),
      totalSalary: totalSalary.toFixed(2),
      remainingProfit: Math.max(0, remainingProfit).toFixed(2),
      newProfitPercentage: newProfitPercentage.toFixed(2),
      decreaseInProfitPercentage: decreaseInProfitPercentage.toFixed(2),
    });
  };

  return (
    <div>
      <div className="navbar">
        <div className="logo">
          <img src={logo} alt="Logo" />
        </div>
      </div>
      <div className="form-container">
        <h1>GP Salary Contribution Calculator</h1>
       
        <div>
          <label>
            Revenue Produced ($):
            <input type="number" value={revenue} onChange={(e) => setRevenue(e.target.value)} />
          </label>
        </div>
        <div>
          <label>
            Margin Percentage (%):
            <input type="number" value={marginPercentage} onChange={(e) => setMarginPercentage(e.target.value)} />
          </label>
        </div>
        <div>
          <label>
            Commission Percentage (%):
            <input type="number" value={commissionPercentage} onChange={(e) => setCommissionPercentage(e.target.value)} />
          </label>
        </div>
        <div>
          <label>
            Minimum Crossover Amount ($):
            <input type="number" value={minCrossoverAmount} onChange={(e) => setMinCrossoverAmount(e.target.value)} />
          </label>
        </div>
        <div>
          <label>
            Monthly Salary ($):
            <input type="number" value={salary} onChange={(e) => setSalary(e.target.value)} />
          </label>
        </div>
        <button onClick={calculateSalary}>Calculate</button>

        {result && (
          <div className="result-container">
            <h2>Calculation Results</h2>
            <p>Gross Profit: ${result.profit}</p>
            <p>Remaining Amount after Crossover: ${result.remainingAmount}</p>
            <p>Commission: ${result.commission}</p>
            <p>Total Broker Monthly Income: ${result.totalSalary}</p>
            <p>Remaining Profit after Total Salary: ${result.remainingProfit}</p>
            <p>New Profit Percentage: {result.newProfitPercentage}%</p>
            <p>Decrease in Profit Percentage: {result.decreaseInProfitPercentage}%</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default App;
